import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const NavItem = props => {
    const location = window.location.pathname;

    return (
        <li className="nav-item">
            <Link to={props.url} className={"nav-link " + (location == props.url ? "!text-red-600" : null)}>
                <FontAwesomeIcon icon={props.icon} className='mx-auto lg:mx-0' />
                <span className='hidden lg:block'>{props.title}</span>
            </Link>
        </li>
    )
}

NavItem.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any
}

export default NavItem