import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faHouse, faBorderAll, faUserGroup, faHome, faGear, faPen, faTrash, faPrint, faPlus, faEye, faRotateRight } from '@fortawesome/free-solid-svg-icons'

import CustomerService from "../services/customer.service";

import { withRouter } from '../common/with-router';
import { Link } from "react-router-dom";
import customerService from "../services/customer.service";

const required = value => {
    if (!value) {
      return (
        <span className="text-red-500 hidden" role="alert">
          Dieses Feld wird benötigt.
        </span>
      );
    }
};

class Customers extends Component {
    constructor(props) {
      super(props);

      this.state = {
        search: "",
        loading: false,
        customers: [],
        showDeletePopup: false,
        customerToDelete: null
      };
    }

    onChangeSearch(e) {
        this.setState({
            search: e.target.value
        });
    }
  
    componentDidMount() {
        setTimeout(
            function() {
                this.loadCustomerList();
            }
            .bind(this),
            500
        );
    }
  
    handleLogin(e) {
      e.preventDefault();
  
      this.setState({
        message: "",
        loading: true
      });
    }

    loadCustomerList() {
        CustomerService.getCustomers().then((output) => {
            this.setState({ customers: output.data }, () => {
                console.log(this.state.customers);
            });
        })
    }

    handleDeleteClick = (customerId) => {
        this.setState({
            showDeletePopup: true,
            customerToDelete: customerId
        })
    }

    async handleDeleteCustomer() {
        try {
            await customerService.deleteCustomerById(this.state.customerToDelete);
            await this.loadCustomerList();
            this.setState({
                showDeletePopup: false,
                customerToDelete: null
            });
        } catch (error) {
          console.error(error);
        }
    }

    render() {

    

    const handleOpenCustomer = customerId => {
        // Handle opening the customer with the given ID
        console.log(`Opening customer ${customerId}`);
      
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-row bg-gray-100 min-h-[7rem] border-b justify-between px-12">
                <h1 className="pageTitle">Kunden</h1>
                
                <div className="flex flex-row my-auto gap-4">
                    <FontAwesomeIcon
                        icon={faRotateRight}
                        onClick={() => this.loadCustomerList()}
                        className="hidden lg:block my-auto text-xl text-red-400 hover:text-red-500 hover:scale-110 active:scale-90 transition-all cursor-pointer" />
                    <input
                        type="text"
                        className="textbox hidden lg:block"
                        name="username"
                        placeholder="Suche"
                        value={this.state.search}
                        onChange={this.onChangeSearch}
                        validations={[required]}
                    />
                    <Link to={"/customer/new/edit"} className="flex h-11 text-white bg-red-400 hover:bg-red-500 border-none hover:scale-95 rounded-[12px] hover:text-white px-4 py-2 m-0 gap-2">
                        <FontAwesomeIcon icon={faPlus} className="my-auto"/>
                        <span className="hidden lg:block my-auto">Neu</span>
                    </Link>
                </div>
            </div>
            
            <div className="page-container">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-100 sticky top-0">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden lg:block">ID</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vorname</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nachname</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {this.state.customers.map(customer => (
                        <tr key={customer.id} className="active:bg-gray-200 cursor-pointer transition-colors lg:bg-transparent" onClick={() => handleOpenCustomer(customer.id)}>
                            <td className="px-6 py-4 whitespace-nowrap hidden lg:block">{customer.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{customer.firstname}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{customer.lastname}</td>
                            <td className="flex flex-row px-6 py-4 gap-2">
                                <Link to={`/customer/${customer.id}`} onClick={() => handleOpenCustomer(customer.id)} className="flex justify-center text-white bg-gray-300 hover:bg-gray-400 border-none hover:scale-95 rounded-[12px] hover:text-white h-10 w-10 m-0">
                                    <FontAwesomeIcon className="self-center" icon={faEye} />
                                </Link>
                                <Link to={`/customer/${customer.id}`} onClick={() => handleOpenCustomer(customer.id)} className="flex justify-center text-white bg-gray-400 hover:bg-gray-500 border-none hover:scale-95 rounded-[12px] hover:text-white h-10 w-10 m-0">
                                    <FontAwesomeIcon className="self-center" icon={faPrint} />
                                </Link>
                                <Link onClick={() => this.handleDeleteClick(customer.id)} className="flex justify-center text-white bg-red-400 hover:bg-red-500 border-none hover:scale-95 rounded-[12px] hover:text-white h-10 w-10 m-0">
                                    <FontAwesomeIcon className="self-center" icon={faTrash} />
                                </Link>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            {this.state.showDeletePopup && (
                <div className="absolute left-0 top-0 flex flex-col justify-center w-full h-full bg-black bg-opacity-20" onClick={() => this.setState({showDeletePopup: false, customerToDelete: null})}>
                    <div className="relative bg-white p-8 w-fit mx-auto rounded overflow-hidden">
                        <h1 className="text-xl font-bold mb-3">Kundendaten löschen</h1>
                        <p className="mb-6">Bitte bestätigen Sie die endgültige Löschung des<br/> Kunden mit der ID: {this.state.customerToDelete}</p>
                        <button className="bg-red-400 py-2 px-3 rounded-[8px] mr-5 hover:scale-95 transition-all ease-in-out" onClick={() => this.handleDeleteCustomer()
                        }>Endgültig löschen</button>
                        <button className="border border-2 border-gray-200 py-2 px-3 rounded-[8px] hover:bg-gray-50 hover:scale-95 transition-all ease-in-out" onClick={() => {
                            this.setState({
                                showDeletePopup: false,
                                customerToDelete: null
                            });
                        }}>Abbrechen</button>

                        <div className="absolute left-0 bottom-0 bg-red-600 h-1 w-full"></div>
                    </div>
                </div>
            )}

        </div>
        
      );
    }
  }
  
  export default withRouter(Customers);
