import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faHouse, faBorderAll, faUserGroup, faHome, faGear, faPen, faTrash, faPrint, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { withRouter } from '../common/with-router';
import { Link, useParams, useSearchParams } from "react-router-dom";
import customerService from "../services/customer.service";
import { useState, useEffect } from "react";
import CustomerEdit from "./CustomerEdit";

const required = value => {
    if (!value) {
      return (
        <span className="text-red-500 hidden" role="alert">
          Dieses Feld wird benötigt.
        </span>
      );
    }
};


function CustomerDetails() {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    customerService.getCustomerDataById(customerId)
      .then(customer => {
        setCustomer(customer.data);
      })
      .catch(error => console.error(error));
  }, [customerId]);

  if (!customer) {
    // customer data has not yet been loaded, show a loading indicator
    //return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row bg-gray-100 min-h-[7rem] border-b justify-between px-12">
        <div className="flex flex-row gap-5 w-full">
          <Link to={"/customer/list"} className="my-auto">
            <FontAwesomeIcon fontSize={32} icon={faArrowLeft}/>
          </Link>
          <h1 className="pageTitle">Kunde: {customer ? customer.firstname + " " + customer.lastname : ""}</h1>
          <div className="hidden lg:flex h-full ml-auto">
            <span className="self-center px-8 py-4 font-bold text-xl opacity-25">Vorschau</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <CustomerEdit isPreview={true} />
      </div>
    </div>
  );
}

export default withRouter(CustomerDetails);

