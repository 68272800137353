import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPrint, faArrowLeft, faSave, faEye, faPen } from '@fortawesome/free-solid-svg-icons'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

import defaultUserImage from "../assets/media/default-user-image.png";

import { withRouter } from '../common/with-router';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";
import customerService from "../services/customer.service";

const required = value => {
  if (!value) {
    return (
      <span className="text-red-500 hidden" role="alert">
        Dieses Feld wird benötigt.
      </span>
    );
  }
};


function CustomerEdit(props) {
  const { customerId } = useParams();
  const { isPreview } = props;
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    customerService.getCustomerDataById(customerId)
      .then(({ data }) => {
        setCustomer(data);
        setFormValues({
          ...data,
          birthdate: dayjs(data.birthdate)
        });
      })
      .catch(error => console.error(error));
  }, [customerId]);  

  const initialValues = {
    firstname: '',
    lastname: '',
    birthdate: dayjs(new Date().toISOString().split('T')[0]),
    zipcode: '',
    city: '',
    street: '',
    phone: '',
    profession: '',
    casehistory: '',
    type: customerId !== 'new' ? 0 : null,
    allergyrecord: false,
    hayfever: false,
    hypothyrosis: false,
    hyperthyroidism: false,
    fatcontent: customerId !== 'new' ? 0 : null,
    moisture: customerId !== 'new' ? 0 : null,
    cornification: customerId !== 'new' ? 0 : null,
    follicle: customerId !== 'new' ? 0 : null,
    thickness: customerId !== 'new' ? 0 : null,
    tension: customerId !== 'new' ? 0 : null,
    sensitivity: customerId !== 'new' ? 0 : null,
    bloodcirculation: customerId !== 'new' ? 0 : null,
    comedones: false,
    sebaceouscysts: false,
    milia: false,
    pustules: false,
    teleangiectasia: false,
    rosacea: false,
    hyperhidrosis: false,
    hypertrichosis: false,
    freckles: false,
    pigmentalmoles: false,
    vitiligo: false,
    scars: false,
    keliods: false,
    notes: ''
  };

  function openPopup() {
    setShowDeletePopup(current => !current)
  }

  function convertDate(isoDate) {
    const dateObj = new Date(isoDate);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    
    return `${day}.${month}.${year} um ${hours}:${minutes} Uhr`;
  }

  function saveCustomer() {
    
    if (customerId == "new") {

      customerService.registerCustomerData(formValues)
        .then(response => {
          console.log('Customer registered successfully:', response.data);
        })
        .catch(error => {
          console.error('Failed to register customer:', error);
      });

    } else {

      customerService.updateCustomerDataById(customerId, formValues)
        .then(response => {
          console.log('Customer data updated:', response.data);
        })
        .catch(error => {
          console.error('Failed to update customer data:', error);
      });

    }

  }
  
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState(() => {
    return Object.keys(initialValues).reduce((acc, key) => {
      return { ...acc, [key]: '' };
    }, {});
  });

  // Calculate age based on birthdate
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const constitutionCheckboxes = [
    {
      name: "allergyrecord",
      label: "Allergiepass",
    },
    {
      name: "hayfever",
      label: "Heuschnupfen",
    },
    {
      name: "hypothyrosis",
      label: "Unterfunktion der Schilddrüse",
    },
    {
      name: "hyperthyroidism",
      label: "Überfunktion der Schilddrüse",
    },
  ];

  const specialCheckboxes = [
    {
      name: "comedones",
      label: "Komodonen",
    },
    {
      name: "sebaceouscysts",
      label: "Talgzysten",
    },
    {
      name: "milia",
      label: "Milien",
    },
    {
      name: "pustules",
      label: "Pusteln",
    },
    {
      name: "teleangiectasia",
      label: "Teleangiektasien",
    },
    {
      name: "rosacea",
      label: "Rosecea",
    },
    {
      name: "hyperhidrosis",
      label: "Hyperhidrosis",
    },
    {
      name: "hypertrichosis",
      label: "Hypertrichosis",
    },
    {
      name: "freckles",
      label: "Sommersprossen",
    },
    {
      name: "pigmentalmoles",
      label: "Pigmentflecken",
    },
    {
      name: "vitiligo",
      label: "Vitiligo",
    },
  ];
  
  const onChangeBirthdateInput = (value) => {

    setFormValues(prevState => ({
      ...prevState,
      birthdate: value,
      age: calculateAge(value)
    }))

  }

  const onChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    var age = 0

    setFormValues({
      ...formValues,
      [name]: value,
      age: age
    });

    validateInput(name, value);
  };

  const onChangeCheckbox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
  
    setFormValues({
      ...formValues,
      [name]: value
    });
  
    validateInput(name, value);
  };

  const validateInput = (name, value) => {
    let error = '';

    switch (name) {
      case 'firstname':
        error = value.length > 0 ? '' : 'Please enter a firstname';
        break;
      case 'lastname':
        error = value.length > 0 ? '' : 'Please enter a lastname';
        break;
      case 'birthdate':
        error = value.length > 0 ? '' : 'Please enter a lastname';
        break;
      case 'residence':
        error = value.length > 0 ? '' : 'Please enter a residence';
        break;
      case 'street':
        error = value.length > 0 ? '' : 'Please enter a residence';
        break;
      case 'phone':
        error = value.length > 0 ? '' : 'Please enter a residence';
        break;
      case 'profession':
        error = value.length > 0 ? '' : 'Please enter a residence';
        break;
      case 'age':
        error = value.length > 0 ? '' : 'Please enter a residence';
        break;
      default:
        break;
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // submit form if there are no errors
    if (Object.values(formErrors).every((error) => error === '')) {
      console.log('Form submitted!');
    }
  };

  const navigate = useNavigate()

  const handleDeleteCustomer = async () => {
    try {
        await customerService.deleteCustomerById(customerToDelete);
        setShowDeletePopup(false);
        setCustomerToDelete(null);
        navigate(`/customer/list`)
    } catch (error) {
      console.error(error);
    }
  }
  
  console.log(customerId)
  return (
    <div className="flex flex-col w-full">
      { !isPreview &&
        <div className="flex flex-row bg-gray-100 min-h-[7rem] border-b justify-between px-12">
          <div className="flex flex-row gap-5 w-full">
            <Link to={"/customer/" + (customerId != "new" ? customerId : "list")} className="my-auto">
              <FontAwesomeIcon fontSize={32} icon={faArrowLeft} />
            </Link>
            <h1 className="pageTitle">
              {customerId != "new" && customer ?
                "Bearbeite: " + customer.firstname + " " + customer.lastname 
                :
                "Neuer Kunde"
              }
            </h1>
          </div>
        </div>
      }
      

      <div className="page-split flex flex-col lg:flex-row h-full w-full overflow-hidden">
        <div className={"page-container p-12 flex-1"}>
          <Form
            className='flex flex-row justify-center'
            onSubmit={handleSubmit}
            autoComplete="off"
          //ref={c => {
          //this.form = c;
          //}}
          >
            <div className="flex flex-col flex-1">


              

              <h1 className="text-2xl font-bold mb-5">Persönliche Daten</h1>
              <div className="flex flex-row flex-wrap gap-5 mb-2">


                <Box
                  component="form"
                  sx={{
                    width: '100%',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px'
                  }}
                  noValidate
                >
                  <Box
                    component={"div"}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      '& > :not(style)': {
                        flex: '1 0 calc(33.3333% - 15px)',
                        flexWrap: 'wrap',
                        minWidth: '200px',
                        width: '100%',
                      },
                    }}
                  >
                    <TextField
                      name="firstname"
                      label="Vorname"
                      value={formValues.firstname}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                      autoComplete="off"
                    />
                    <TextField
                      name="lastname"
                      label="Nachname"
                      value={formValues.lastname}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                       autoComplete="off"
                    />
                    <DatePicker
                      ti
                      label={"Geburtsdatum " + (formValues.birthdate.length > 0 ? "(Alter: " + formValues.age + ")" : "")}
                      name="birthdate"
                      format="DD.MM.YYYY"
                      value={formValues.birthdate}
                      onChange={(newValue) => onChangeBirthdateInput(dayjs(newValue).format('YYYY-MM-DD'))}
                      disabled={isPreview}
                    />

                    <TextField
                      name="zipcode"
                      label="Postleitzahl"
                      value={formValues.zipcode}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                       autoComplete="off"
                    />

                    <TextField
                      name="city"
                      label="Stadt"
                      value={formValues.city}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                      autoComplete="off"
                    />

                    <TextField
                      name="street"
                      label="Straße"
                      value={formValues.street}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                       autoComplete="off"
                    />

                    <TextField
                      name="phone"
                      label="Telefonnummer"
                      value={formValues.phone}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                       autoComplete="off"
                    />

                    <TextField
                      name="profession"
                      label="Beruf"
                      value={formValues.profession}
                      onChange={onChangeInput}
                      variant="outlined"
                      disabled={isPreview}
                       autoComplete="off"
                    />
                  </Box>

                  <h1 className="text-2xl font-bold my-5">Diagnose</h1>

                  <Box
                    component={"div"}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      '& > :not(style)': {
                        flex: '1 0 calc(33.3333% - 15px)',
                        flexWrap: 'wrap',
                        minWidth: '200px',
                        width: '100%',
                      },
                    }}
                  >
                    <TextField
                      name="casehistory"
                      label="Anamnese (Vorgeschichte)"
                      value={formValues.casehistory}
                      onChange={onChangeInput}
                      variant="outlined"
                      multiline
                      minRows={2}
                      maxRows={6}
                      disabled={isPreview}
                      autoComplete="off"
                    />
                  </Box>

                  <Box
                    component={"div"}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      '& > :not(style)': {
                        flex: '1 0 calc(33.3333% - 15px)',
                        flexWrap: 'wrap',
                        minWidth: '200px',
                        width: '100%',
                        margin: '0 0 15px',
                      },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="type"
                        value={formValues.type}
                        label="Typ"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>schlank (leptosom)</MenuItem>
                        <MenuItem value={1}>sportlich (athletisch)</MenuItem>
                        <MenuItem value={2}>vollschlank (pyknisch)</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <h2 className="text-lg font-bold my-5">Differenzierung der Konstitution</h2>

                  <Box
                    component={"div"}
                    sx={{
                      width: '100%',
                      flexDirection: 'row',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      '& > :not(style)': {
                        flex: '1 0 calc(33.3333% - 15px)',
                        flexWrap: 'wrap',
                        minWidth: '300px',
                        width: '100%',
                      },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Fettgehalt</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="fatcontent"
                        value={formValues.fatcontent}
                        label="Fettgehalt"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>leicht</MenuItem>
                        <MenuItem value={1}>trocken</MenuItem>
                        <MenuItem value={2}>ölig</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Feuchtigkeit</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="moisture"
                        value={formValues.moisture}
                        label="Feuchtigkeit"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>normal</MenuItem>
                        <MenuItem value={1}>spröde</MenuItem>
                        <MenuItem value={2}>schmierig</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Verhornung</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="cornification"
                        value={formValues.cornification}
                        label="Verhornung"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>normal</MenuItem>
                        <MenuItem value={1}>pergamentartig</MenuItem>
                        <MenuItem value={2}>schuppig</MenuItem>
                        <MenuItem value={3}>lamellenförmig</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Follikel</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="follicle"
                        value={formValues.follicle}
                        label="Follikel"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>normal</MenuItem>
                        <MenuItem value={1}>feinporig</MenuItem>
                        <MenuItem value={2}>groß</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Dicke</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="thickness"
                        value={formValues.thickness}
                        label="Dicke"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>normal</MenuItem>
                        <MenuItem value={1}>dünn</MenuItem>
                        <MenuItem value={2}>ausgeprägtes Relief</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Spannung</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="tension"
                        value={formValues.tension}
                        label="Spannung"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>straff</MenuItem>
                        <MenuItem value={1}>verminderter Tonus und Tugor</MenuItem>
                        <MenuItem value={2}>Spannungsgefühl</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Empfindlichkeit</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="sensitivity"
                        value={formValues.sensitivity}
                        label="Empfindlichkeit"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>normal</MenuItem>
                        <MenuItem value={1}>unempfindlich</MenuItem>
                        <MenuItem value={2}>hypersensibel</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Durchblutung</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        name="bloodcirculation"
                        value={formValues.bloodcirculation}
                        label="Empfindlichkeit"
                        onChange={onChangeInput}
                        disabled={isPreview}
                      >
                        <MenuItem value={0}>gut durchblutet</MenuItem>
                        <MenuItem value={1}>blass</MenuItem>
                        <MenuItem value={2}>gerötet</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <FormGroup
                    sx={{
                    width: '100%',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    '& > :not(style)': {
                      flex: '1 0 calc(33.3333% - 15px)',
                      flexWrap: 'wrap',
                      minWidth: '300px',
                      width: '100%',
                    },
                  }}
                  >
                    {constitutionCheckboxes.map((data) => (
                      <FormControlLabel
                        key={data.name}
                        control={
                          <Checkbox
                            checked={formValues[data.name]}
                            onChange={onChangeCheckbox}
                            name={data.name}
                          />
                        }
                        label={data.label}
                        disabled={isPreview}
                      />
                    ))}
                  </FormGroup>

                  <h2 className="text-lg font-bold my-5">Besondere Erscheinungen</h2>

                  <FormGroup
                    sx={{
                    width: '100%',
                    flexDirection: 'row',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    '& > :not(style)': {
                      flex: '1 0 calc(33.3333% - 15px)',
                      flexWrap: 'wrap',
                      minWidth: '300px',
                      width: '100%',
                    },
                  }}
                  >
                    {specialCheckboxes.map((data) => (
                      <FormControlLabel
                        key={data.name}
                        control={
                          <Checkbox
                            checked={formValues[data.name]}
                            onChange={onChangeCheckbox}
                            name={data.name}
                          />
                        }
                        label={`${data.label}`}
                        disabled={isPreview}
                      />
                    ))}
                    <span></span>
                  
                  </FormGroup>
                </Box>
              </div>

              <h2 className="text-lg font-bold mt-5 mb-8">Intern</h2>
              <TextField
                id="filled-helperText"
                name="notes"
                label="Private Notizen"
                value={formValues.notes}
                onChange={onChangeInput}
                variant="outlined"
                multiline
                minRows={2}
                maxRows={6}
                disabled={isPreview}
              />

            </div>
          </Form>

          <div className="lg:hidden flex flex-col mt-10 gap-1">
            { !isPreview ?
              <>
                <Link to={customerId == "new" ? "/customer/list" : "/customer/" + customerId} className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                  <FontAwesomeIcon className="my-auto" icon={faTrash} />
                  <span className="my-auto">Verwerfen</span>
                </Link>
                { customerId != "new" &&
                  <Link to={"/customer/" + customerId} className="flex flex-row justify-center text-lg text-black bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                    <FontAwesomeIcon className="my-auto" icon={faEye} />
                    <span className="my-auto">Vorschau</span>
                  </Link>
                }
                <Link to={"/customer/list"} onClick={() => saveCustomer()} className="flex flex-row justify-center text-lg text-white bg-red-700 hover:bg-red-800 transition-colors h-20 gap-5">
                  <FontAwesomeIcon className="my-auto" icon={faSave} />
                  <span className="my-auto">Speichern</span>
                </Link>
              </>
              :
              <>
                <button className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5"
                  onClick={() => {
                    setCustomerToDelete(customerId);
                    setShowDeletePopup(true);
                  }}>
                  <FontAwesomeIcon className="my-auto" icon={faTrash} />
                  <span className="my-auto">Löschen</span>
                </button>
                { customerId != "new" &&
                  <button className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                    <FontAwesomeIcon className="my-auto" icon={faPrint} />
                    <span className="my-auto">Drucken</span>
                  </button>
                }
                <Link to={"/customer/" + customerId + "/edit"} className="flex flex-row justify-center text-lg text-white bg-red-700 hover:bg-red-800 transition-colors h-20 gap-5">
                  <FontAwesomeIcon className="my-auto" icon={faPen} />
                  <span className="my-auto">Bearbeiten</span>
                </Link>
              </>
          }
          </div>

        </div>

        <div className="hidden lg:flex flex-col w-full lg:max-w-xs border-l shadow">
          <div className="flex flex-col mb-auto mx-auto mt-12 gap-5 text-center px-5">
            { customerId != "new" ?
              <>
                <p className="text-xl font-bold">{customer && customer.firstname} {customer && customer.lastname}</p>
                <hr />
                <p className="text-gray-400">Erstellt:<br/>{customer && convertDate(customer.createdAt)}</p>
                <p className="text-gray-400">Zuletzt bearbeitet:<br/>{customer && convertDate(customer.updatedAt)}</p>
              </>
              :
              <>
                <p className="text-xl font-bold">Neuer Kunde</p>
                <hr />
                <p className="text-md">{formValues.firstname} {formValues.lastname}</p>
              </>
          }
          </div>
            { !isPreview &&
            <>
              <Link to={customerId == "new" ? "/customer/list" : "/customer/" + customerId} className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                <FontAwesomeIcon className="my-auto" icon={faTrash} />
                <span className="my-auto">Verwerfen</span>
              </Link>
              { customerId != "new" &&
                <button className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                  <FontAwesomeIcon className="my-auto" icon={faEye} />
                  <span className="my-auto">Vorschau</span>
                </button>
              }
              <Link
                className="flex flex-row justify-center text-lg text-white bg-red-700 hover:bg-red-800 transition-colors h-20 gap-5"
                to={"/customer/list#reload"}
                onClick={() => saveCustomer()}
              >
                <FontAwesomeIcon className="my-auto" icon={faSave} />
                <span className="my-auto">Speichern</span>
              </Link>
            </>
          }
          { isPreview &&
            <>
              <button className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5"
                onClick={() => {
                  setCustomerToDelete(customerId);
                  setShowDeletePopup(true);
                }}>
                <FontAwesomeIcon className="my-auto" icon={faTrash} />
                <span className="my-auto">Löschen</span>
              </button>
              <button className="flex flex-row justify-center text-lg bg-gray-200 hover:bg-gray-300 transition-colors h-20 gap-5">
                <FontAwesomeIcon className="my-auto" icon={faPrint} />
                <span className="my-auto">Drucken</span>
              </button>
              <Link
                className="flex flex-row justify-center text-lg text-white bg-red-700 hover:bg-red-800 transition-colors h-20 gap-5"
                to={"/customer/" + customerId + "/edit"}
              >
                <FontAwesomeIcon className="my-auto" icon={faPen} />
                <span className="my-auto">Bearbeiten</span>
              </Link>
            </>
          }
          
        </div>
      </div>

      {showDeletePopup && (
          <div className="absolute z-50 left-0 top-0 flex flex-col justify-center w-full h-full bg-black bg-opacity-20" onClick={() => {
            setShowDeletePopup(false)
            setCustomerToDelete(null)
          }}>
              <div className="relative bg-white p-8 w-fit mx-auto rounded overflow-hidden">
                  <h1 className="text-xl font-bold mb-3">Kundendaten löschen</h1>
                  <p className="mb-6">Bitte bestätigen Sie die endgültige Löschung des<br/> Kunden mit der ID: {customerToDelete}</p>
                  <button className="bg-red-400 py-2 px-3 rounded-[8px] mr-5 hover:scale-95 transition-all ease-in-out" onClick={() => handleDeleteCustomer()
                  }>Endgültig löschen</button>
                  <button className="border border-2 border-gray-200 py-2 px-3 rounded-[8px] hover:bg-gray-50 hover:scale-95 transition-all ease-in-out" onClick={() => {
                    setShowDeletePopup(false)
                    setCustomerToDelete(null)
                  }}>Abbrechen</button>

                  <div className="absolute left-0 bottom-0 bg-red-600 h-1 w-full"></div>
              </div>
          </div>
      )}

    </div>
  );
}

export default withRouter(CustomerEdit);
