import React, { Component } from "react";
import { withRouter } from '../common/with-router';

class Settings extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      const { currentUser } = this.props;
      const formattedName = currentUser.username.split('.')
      .map(name => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');

      return (
        <div className="flex flex-col w-full">
          <div className="flex flex-row bg-gray-100 min-h-[7rem] border-b justify-between px-12">
              <h1 className="pageTitle">Einstellungen</h1>
          </div>
          <div className="page-container flex flex-col w-full p-12 gap-4">

          <section className="flex flex-col gap-2">
            <h2 className="sectionTitle">Profil</h2>
            <span>Eingeloggt als: <b>{currentUser && formattedName}</b></span>
            <span>Berechtigungen: <b>{currentUser && currentUser.roles.includes('ROLE_ADMIN') ? 'Administrator' : currentUser.roles.join(', ')}</b></span>
            <span>Letzter Standort (IP): </span>
          </section>
            
        </div>
        </div>
       
        
        
      );
    }
  }
  
  export default withRouter(Settings);
