import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://api.beautylounge.ks.timklose.com/customer/';

class CustomerService {
  getCustomers() {
    return axios.get(API_URL + 'all', { headers: authHeader() });
  }

  getCustomerDataById(id) {
    return axios.get(API_URL + id, {headers: authHeader() });
  }

  updateCustomerDataById(id, data) {
    return axios.post(API_URL + id + "/update", data, {headers: authHeader() });
  }

  registerCustomerData(data) {
    return axios.post(API_URL + "new", data, {headers: authHeader() });
  }

  deleteCustomerById(id) {
    return axios.delete(API_URL + id, {headers: authHeader() });
  }
  
}

export default new CustomerService();