import React from 'react'
import PropTypes from 'prop-types'

const DashboardCard = props => {
  return (
    <div className="flex flex-col bg-white border p-4 w-full flex-1 h-28 justify-between hover:border-red-200 transition-colors">
        <span className="font-bold">{props.title}</span>
        <span className="text-3xl font-bold ml-auto">{props.value}</span>
    </div>
  )
}

DashboardCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number
}

export default DashboardCard