import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginRedirect = (props) => {
  const navigate = useNavigate();

  useEffect(() => {

    if (props.toDashboard) {
      navigate('/dashboard');
    } else {
      navigate('/oauth2/login');
    }
    
  }, [navigate]);

  return null;
}

export default LoginRedirect;
