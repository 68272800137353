import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button"
import Chart from "react-apexcharts";
import ApexChart from "../components/DashboardChart";

import { withRouter } from '../common/with-router';
import DashboardCard from "../components/Cards/DashboardCard";


class Dashboard extends Component {
    constructor(props) {
      super(props);

      this.state = {
        username: "",
        usernameValid: true,
        password: "",
        passwordValid: true,
        loading: false,
        message: "",
      };
    }
  
  
    handleLogin(e) {
      e.preventDefault();
  
      this.setState({
        message: "",
        loading: true
      });
    }
  
    render() {
      return (
        <div className="flex flex-col w-full">
          <div className="flex flex-row bg-gray-100 min-h-[7rem] border-b justify-between px-12">
              <h1 className="pageTitle">Dashboard</h1>
          </div>
          <div className="page-container flex flex-col w-full p-12 gap-4">
            

            <section>
                <h2 className="sectionTitle">Übersicht</h2>
                <div className="flex flex-wrap flex-col lg:flex-row gap-4 mb-4">
                    <DashboardCard title={"Kunden"} value={301} highlight={true} />
                    <DashboardCard title={"Mitarbeiter"} value={3} highlight={true} />
                    <DashboardCard title={"Behandlungen"} value={52} highlight={true} />
                    <DashboardCard title={"Verkäufe"} value={14} highlight={true} />
                </div>
            </section>

            <section>
                <h2 className="sectionTitle">Statistiken</h2>

                <ApexChart />
                <span className="text-sm text-red-700 my-4">Beispieldaten</span>
            </section>
            

            
        </div>
        </div>
       
        
        
      );
    }
  }
  
  export default withRouter(Dashboard);
