import React, { Component, Suspense } from "react";
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faHouse, faBorderAll, faUserGroup, faHome, faGear } from '@fortawesome/free-solid-svg-icons'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "dayjs/locale/de";

import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./pages/Login";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import { withRouter } from "./common/with-router";
import LoginRedirect from "./pages/LoginRedirect";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import NavItem from "./components/Navigationbar/NavItem.component";
import CustomerDetails from "./pages/CustomerDetails";
import CustomerEdit from "./pages/CustomerEdit";
import LoadingCard from "./components/LoadingCard";
import Settings from "./pages/Settings";

import Logo from "./assets/media/logo-beauty-lounge.png";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    // This function will be executed whenever the route changes
    const body = document.querySelector("body");
    function removeLoader() {
      body.classList.remove("loading");
    }

    function showLoader() {
      body.classList.add("loading");
    }

    setTimeout(removeLoader, 0);
    setTimeout(showLoader, 500);
    setTimeout(removeLoader, 100);
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
   
    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;
    const location = window.location.pathname;

    let routes = (
      <Routes>
        <Route exact path="*" element={<LoginRedirect/>} />
        <Route exact path="/oauth2/login" element={<Login/>} />
      </Routes>
    )

    // when user is signed in
    if (currentUser) {
      routes = (
        <Routes>
          <Route exact path="/" element={<Login/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/customer/list" element={<Customers/>} />
          <Route path="/customer/:customerId" element={<CustomerDetails/>} />
          <Route path="/customer/:customerId/edit" element={<CustomerEdit/>} />
          <Route path="/settings" element={<Settings currentUser={this.state.currentUser}/>} />
          <Route exact path="/oauth2/login" element={<LoginRedirect toDashboard={true}/>} />
        </Routes>
      )
    }

    return (
      
      <div className="flex flex-row w-screen">

        {currentUser && (
          <nav className="flex flex-col bg-white border-r min-w-[5rem] w-20 lg:w-[25vw] max-w-[20rem] h-screen justify-between overflow-hidden shadow overflow-y-hidden">
            <div className="flex flex-col gap-8">
              <Link to={"/"} className={"flex flex-col gap-4 bg-red-700 navbar-brand transition-all border-b h-28 " + (location.includes("/dashboard") ? "lg:h-40" : null)}>
                <img className={'m-auto transition-all h-10 ' + (location.includes("/dashboard") ? "lg:h-24" : "lg:h-16")} src={Logo} />
              </Link>

              <ul className="nav-items">
                <li className={"nav-item mb-8 hidden lg:block transition-all " + (location.includes("/dashboard") ? "h-0 opacity-0" : "h-12")}>
                  <span className="opacity-50 px-4 py-2">Kundenverwaltung</span>
                  {/* 
                  <label htmlFor="Toggle4" className="inline-flex items-center p-1 gap-1 cursor-pointer bg-gray-200 rounded-[12px] text-gray-800">
                    <input id="Toggle4" type="checkbox" className="hidden peer" />
                    <span className="px-4 py-2 bg-white peer-checked:bg-transparent rounded-[8px] text-black transition-colors">Menü</span>
                    <span className="px-4 py-2 peer-checked:bg-white rounded-[8px] text-black transition-colors">Optionen</span>
                  </label>
                  */}
                </li>


                <NavItem title={"Dashboard"} url={"/dashboard"} icon={faHome} />
                <NavItem title={"Kunden"} url={"/customer/list"} icon={faUserGroup} />
                <NavItem title={"Einstellungen"} url={"/settings"} icon={faGear} />
                
              </ul>
            </div>

            <div className="flex flex-col">
              <span className="text-center p-2 text-sm opacity-40">A Tim Klose Solution</span>
              {currentUser && (
                <div className="flex flex-col xl:flex-row xl:gap-4 xl:h-20 w-full bg-gray-200 border-t justify-between overflow-x-scoll">
                  <div className="hidden p-5 lg:block">
                    <span className="text-black font-bold uppercase">{currentUser.username}</span>
                    <Link to={"/settings#profile"} className="flex flex-col my-auto text-sm text-red-600 hover:text-black">
                      Profil aufrufen
                    </Link>
                  </div>
                  <a href="/oauth/login" className="nav-link h-14 w-full xl:h-full xl:max-w-[100px] text-gray-800 bg-slate-300 py-2 px-3 m-0 rounded-[0] hover:bg-slate-400" onClick={this.logOut}>
                    <FontAwesomeIcon icon={faRightFromBracket} className="mx-auto"/>
                  </a>
                </div>
              )}
            </div>
          </nav>
        )}

        <div className={"flex w-full m-auto " + (!location.includes("oauth") ? "h-screen" : null)}>

          <Suspense fallback={<h1>Wird geladen...</h1>}>
            <LocalizationProvider adapterLocale={"de"} dateAdapter={AdapterDayjs}>
              {routes}
            </LocalizationProvider>
          </Suspense>

        </div>

        {/* <AuthVerify logOut={this.logOut}/> */}
      </div>
    );
  }
}

export default withRouter(App);